<template>
  <div>
    <header>
      <BCard title="Ações">
        <div>
          <b-form-checkbox
            @change="swtichAllowInactivate"
            v-model="allowInactivates"
            name="check-button"
            switch
          >
            Mostrar inativos?
          </b-form-checkbox>
        </div>
        <div
          :style="{
            overflowX: 'auto',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            border: '1px solid',
            borderColor: '#d5d5d5',
            borderRadius: '4px',
            padding: '5px',
          }"
        >
          <input type="checkbox" @change="toggleAll" :checked="selectAll" />
          <label for="toggle-all" :style="{ marginRight: '15px' }">Todos</label>

          <div
            v-for="user in users"
            :key="user.member_id"
            :style="{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              marginRight: '15px',
              textAlign: 'center',
            }"
          >
            <input
              type="checkbox"
              :value="user.user.username"
              @change="
                getTasksBySelect(user && user.member ? user.member.id : null)
              "
              :checked="
                idArr.includes(user && user.member ? user.member.id : null)
              "
            />
            <span
              class="custom-bullet"
              @click="handleClickBullet(user)"
              :style="{
                borderRadius: '50%',
                display: 'block',
                color: user && user.member ? user.member.color : null,
                background: user && user.member ? user.member.color : null,
                width: '15px',
                height: '15px',
                margin: '5px 5px 0px 10px',
              }"
            ></span>
            <p
              :style="{
                marginTop: '5px',
                fontSize: '12px',
              }"
            >
              {{ user && user.user ? user.user.username : 'null' }}
            </p>
          </div>
        </div>

        <!-- SELECT User -->

        <b-row class="justify-content-center align-items-center">
          <b-col cols="6">
            <!-- DATA INI -->
            <b-form-group>
              <label for="transactionDate" class="font-weight-bold text-primary"
                >Ir para data:</label
              >
              <!-- <b-form-input id="transactionDate" type="date"></b-form-input> -->
              <b-form-datepicker
                v-model="start_week_day"
                :date-disabled-fn="dateDisabled"
                @input="updateSchedulerWeek"
                locale="pt-br"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="justify-content-center">
            <b-button
              @click="
                list = !list;
                refreshTable();
              "
              variant="outline-primary"
              class="btn-tour-skip btn-list mr-1"
            >
              {{ list ? 'Agenda' : 'Lista' }}
            </b-button>
          </b-col>
          <b-col cols="4" class="justify-content-center">
            <b-button block variant="success" @click="openModalAction"
              >+ Criar nova tarefa</b-button
            >
          </b-col>
        </b-row>
      </BCard>
    </header>
    <BCard>
      <div class="calendar-wrapper">
        <div v-show="!list">
          <vue-scheduler
            :key="refresh"
            ref="scheduler"
            class="custom-scheduler"
            locale="pt-br"
            :min-date="null"
            :max-date="null"
            :labels="{
              today: 'Hoje',
              back: 'Anterior',
              next: 'Seguinte',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              all_day: 'Todos os dias',
            }"
            :time-range="[0, 23]"
            :available-views="[]"
            :active-date="schedulerInitialDate"
            initial-view="week"
            :showTodayButton="false"
            :events="events"
            :event-display="eventDisplay"
            disableDialog
            @month-changed="monthChanged"
            @week-changed="weekChanged"
            @day-changed="dayChanged"
            @view-changed="viewChanged"
            @day-clicked="dayClicked"
            @time-clicked="timeClicked"
            @event-clicked="eventClicked"
            @event-created="eventCreated"
          />
        </div>
        <div v-show="list">
          <List
            @getTasks="getTasks"
            @openUpdateModal="handleUpdateModalEmit"
            :items="items"
            :currentPage="currentPage"
            :totalRows="totalRows"
          />
        </div>
      </div>
      <!-- CODIGO PARA SER IMPLEMENTADO -->

      <!-- <DayPilotCalendar /> -->
      <Modal
        :pre_time="preset_data"
        :costs_variables="costs_variables"
        @closeModal="closeModal"
        :open.sync="isModalOpen"
      />
      <ModalUpdate
        :_item="eventSelect"
        :costs_variables="costs_variables"
        @closeModal="closeModal"
        :open="modalUpdate"
      />

      <BModal
        title="Alterar cor de usuário"
        v-model="userColorChanger.modalOpen"
        hide-footer
      >
        <b-form>
          <b-row
            class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
          >
            <b-col col xs="12" sm="12" md="6" lg="6">
              <b-form-group>
                <label
                  for="value"
                  class="text-center font-weight-bold text-primary"
                >
                  <strong>{{ userColorChanger.username }}:</strong></label
                >

                <b-form-input
                  id="value"
                  type="color"
                  v-model="userColorChanger.color"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button block variant="warning" @click="handleChangeColor()"
            >Confirmar</b-button
          >
          <b-button block @click="userColorChanger.modalOpen = false"
            >Voltar</b-button
          >
        </b-form>
      </BModal>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import { differenceInMinutes } from 'date-fns';
import Modal from './Modal.vue';
import List from './List.vue';
import ModalUpdate from './Update.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'v-calendar-scheduler/lib/main.css';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BFormCheckbox,
    BButton,
    BCol,
    Modal,
    ModalUpdate,
    List,
    BFormDatepicker,
  },
  data() {
    return {
      events: [
        // {
        //   date: new Date(),
        //   startTime: '13:00',
        //   endTime: '15:00',
        //   name: 'Date with David',
        //   comments: 'MUST bring chocolate.',
        //   customAttribute: "I'm a custom attribute",
        //   colorClass: 'red',
        //   color: '#000',
        // },
      ],
      isModalOpen: false,
      startDate: '',
      idArr: [],
      selectAll: true,
      endDate: '',
      preset_data: { start: '', end: '', date: '' },
      modalOpen: false,
      modalUpdate: false,
      currentDate: new Date(),
      member: {},
      users: [],
      list: false,
      dismount: true,
      eventSelect: {},
      timeSelect: '',
      dateSelect: '',
      items: [],
      project_stages: [],
      schedulerInitialDate: null,
      userColorChanger: { modalOpen: false },
      selectedMember: '',
      start_week_day: '',
      end_week_day: '',
      allowInactivates: false,
      firstRender: true,
      currentPage: 1,
      totalRows: '',
      refresh: 0,
      costs_variables: {
        average_office_cost: '',
        hour_per_month: '',
      },
    };
  },
  mounted() {
    this.idArr = this.users.map((user) => user.member_id);
    this.getTasksBySelect(this.idArr);
  },
  async created() {
    //this.getWeekDates();
    await this.getPastYearOfficeCost();
    this.getAllUsers();
    this.getTasks();

    this.$nextTick(() => {
      const calendarWrapper = this.$el.querySelector(
        '.v-cal-content .v-cal-days'
      );
      if (calendarWrapper) {
        calendarWrapper.scrollTop = calendarWrapper.scrollHeight * 0.5; // Ajusta para 80% rolada
      }
    });
  },
  watch: {
    selectedMember: function (newMemberId) {
      this.dismount = true;
      this.event = [];
      this.getTasks(newMemberId);
      this.dismount = false;
    },
  },
  methods: {
    async getProjectStages() {
      this.project_stages = [];
      this.$store
        .dispatch('getProjectStages', {
          project_id: this.item.project_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_stages = resp.map((item) => ({
            id: item.id,
            stage_name: item.stage_name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.idArr = this.selectAll ? this.users.map((user) => user.id) : [];
      this.getTasksBySelect(this.idArr);
    },

    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return (
        weekday === 1 ||
        weekday === 2 ||
        weekday === 3 ||
        weekday === 4 ||
        weekday === 5 ||
        weekday === 6
      );
    },
    refreshTable() {
      console.log('refresh', this.start_week_day);
      if (this.$refs.scheduler) {
        this.refresh += 1;
        this.schedulerInitialDate = new Date(this.start_week_day);
        this.schedulerInitialDate.setHours(
          this.schedulerInitialDate.getHours() + 3
        );

        this.schedulerInitialDate.setDate(this.schedulerInitialDate.getDate());

        //this.weekChanged(this.start_week_day);
        this.$nextTick(() => {
          if (this.$refs.scheduler) {
            this.$refs.scheduler.$emit(
              'week-changed',
              this.schedulerInitialDate
            );
            // console.log(this.$refs.scheduler.activeDate);
            this.$refs.scheduler.activeDate = moment(this.schedulerInitialDate);
          }
        });
      }
    },
    async getAllUsers() {
      if (!this.userList) {
        this.$store
          .dispatch('getAllWorkspaceMembers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: this.allowInactivates,
          })
          .then((resp) => {
            console.log('resp', resp);
            if (resp) {
              this.preset_data.users = resp;
              this.users = resp;
            }
            this.users.forEach((user) => {
              this.idArr.push(user.member.id);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
    async getTasks(val) {
      this.currentPage = val ? val : 1;
      (this.isBusy = true),
        this.$store
          .dispatch('getAllProjectTasks', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            inactive: this.allowInactivates,
            page: this.currentPage,
            start_date: this.start_week_day,
            end_date: this.end_week_day,
            perPage: 10,
          })
          .then((resp) => {
            this.events = [];

            this.items = resp.data;
            this.totalRows = resp.pagination.total;

            this.items.map((dialog) => {
              this.events.push({
                ...dialog,
                color: dialog.member.color,
                project_code: dialog.project.project_code,
                project_name: dialog.project.project_name,
                date: dialog.date,
                startTime: dialog.start,
                endTime: dialog.end,
                name: dialog.task_title
                  ? dialog.task_title.task_title
                  : dialog.title,
                comments: dialog.notes,
                username: dialog.member.access.user.username,
              });
            });
            //console.log(resp);
            this.isBusy = false;
            if (resp === '') {
              this.items = [];
            }
            this.dismount = true;
          })
          .catch((err) => {
            console.log(err);
          });
      this.loading = false;
    },
    // async getTasks(member_id) {
    //   let id = member_id || '';
    //   console.log(this.start_week_day, this.end_week_day);

    //   const dispatchType = id !== '' ? 'getMyTasks' : 'getAllTasks';

    //   this.$store
    //     .dispatch(dispatchType, {
    //       start_date: this.start_week_day,
    //       end_date: this.end_week_day,
    //       member_id: id ?? '',
    //       workspace_id: this.$store.getters.currentWorkspace.id,
    //       inactive: this.allowInactivates
    //     })
    //     .then(resp => {
    //       // console.log({ resp });
    //       this.dismount = false;
    //       // console.log({ dispatchType, resp, id });
    //       this.items = resp;
    //       console.log(resp);
    //       this.events = [];
    //       if (resp === undefined) {
    //         this.items = [];
    //       }

    //       this.items.map(dialog => {
    //         this.events.push({
    //           ...dialog,
    //           project_code: dialog.project_code,
    //           date: dialog.date,
    //           startTime: this.formatTime(dialog.start),
    //           endTime: this.formatTime(dialog.end),
    //           name: dialog.title,
    //           comments: dialog.notes,
    //           username: dialog.username
    //         });
    //       });

    //       this.dismount = true;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    async getTasksBySelect(member_id) {
      if (typeof member_id != 'object') {
        this.idArr = this.idArr.includes(member_id)
          ? this.idArr.filter((id) => id !== member_id)
          : [...this.idArr, member_id];
      }

      this.$store
        .dispatch('getMyBySelect', {
          start_date: this.start_week_day,
          end_date: this.end_week_day,
          member_id: this.idArr,
          workspace_id: this.$store.getters.currentWorkspace.id,
          inactive: this.allowInactivates,
          type: 'select',
          page: this.currentPage,
          perPage: 10,
        })
        .then((resp) => {
          this.dismount = false;
          this.items = resp.data;
          this.totalRows = resp.pagination.total;
          this.events = [];
          if (resp === '') {
            this.items = [];
          }

          this.items.map((dialog) => {
            console.log('dialog', dialog);
            this.events.push({
              ...dialog,
              color: dialog.member.color,
              project_code: dialog.project.project_code,
              date: dialog.date,
              startTime: dialog.start,
              endTime: dialog.end,
              name: dialog.task_title
                ? dialog.task_title.task_title
                : dialog.title,
              comments: dialog.notes,
              username: dialog.member.access.user.username,
            });
          });
          this.dismount = true;
        })
        .catch((err) => {
          console.log(err);
          this.items = [];
          this.events = [];
        });
    },
    formatTime(timeString) {
      const time = new Date(timeString).toISOString().slice(11, 16);
      return time;
    },

    async handleClickBullet(item) {
      this.userColorChanger = { ...item, modalOpen: true };
    },

    async handleChangeColor() {
      this.$store
        .dispatch('updateOneWorkspaceMembers', {
          color: this.userColorChanger.color,
          member_id: this.userColorChanger.member_id,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Alterado com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.userColorChanger.modalOpen = false;
          this.getAllUsers();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao alterar cor do usuário.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    onlyHours(dataString) {
      const data = new Date(dataString);
      data.setHours(data.getHours());

      const horas = data.getHours();
      const minutos = data.getMinutes();

      const horasFormatadas = horas < 10 ? '0' + horas : horas;
      const minutosFormatados = minutos < 10 ? '0' + minutos : minutos;

      return `${horasFormatadas}:${minutosFormatados}`;
    },

    eventDuration(endTime, startTime) {
      const end = new Date(endTime);
      const start = new Date(startTime);

      const totalMinutes = differenceInMinutes(end, start);

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    },
    eventDisplay(event) {
      const display = `${event.priority == 'Alta' ? '⭐' : ''} ${
        event.project_code
      } - ${event.name}\nInicio: ${this.onlyHours(
        event.startTime
      )}\nFim: ${this.onlyHours(event.endTime)}\nDuração: ${this.eventDuration(
        event.endTime,
        event.startTime
      )}\nNotas: ${event.notes === null ? '' : event.notes}`;
      // eslint-disable-next-line quotes
      return display;
    },
    previousWeek() {
      //console.log('next week');
      const newDate = new Date(this.currentDate);
      newDate.setDate(newDate.getDate() - 7);
      this.$set(this, 'currentDate', newDate);

      // this.getTasks(this.selectedMember);
    },
    nextWeek() {
      //console.log('next week');
      const newDate = new Date(this.currentDate);
      newDate.setDate(newDate.getDate() + 7);
      this.$set(this, 'currentDate', newDate);

      // this.getTasks(this.selectedMember);
    },
    openModalAction() {
      this.isModalOpen = true;
    },
    openModalUpdateAction() {
      this.modalUpdate = true;
    },

    handleUpdateModalEmit(item) {
      this.eventSelect = item;
      this.openModalUpdateAction();
    },
    closeModal() {
      this.events = [];
      this.isModalOpen = false;
      this.modalUpdate = false;
      this.getTasks(this.selectedMember);
    },

    getWeekDates() {
      var today = new Date();
      var dayOfWeek = today.getDay();

      var daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek;

      var startDate = new Date(today);
      startDate.setDate(today.getDate() - daysToMonday);

      var endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      this.startDate = startDate;
      this.endDate = endDate;

      this.start_week_day = this.dateFormatter(startDate, 'yyyy-MM-dd');
      this.end_week_day = this.dateFormatter(endDate, 'yyyy-MM-dd');
    },

    swtichAllowInactivate(e) {
      console.log('evnt', e);
      this.allowInactivates = e;
      this.idArr = [];
      this.events = [];
      this.items = [];
      this.getAllUsers();
      this.getTasks('');
    },
    monthChanged(newDate) {
      console.log(newDate);
    },
    weekChanged(newDate) {
      this.schedulerInitialDate =
        typeof newDate === 'string' ? new Date(newDate) : newDate;
      const today = new Date(this.schedulerInitialDate);

      const dayOfWeek = today.getDay();
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - dayOfWeek);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      this.start_week_day = this.dateFormatter(startDate, 'yyyy-MM-dd');
      this.end_week_day = this.dateFormatter(endDate, 'yyyy-MM-dd');

      this.getTasks();
    },
    dayChanged(newDate) {
      console.log('Day Changed');
      console.log(newDate);
    },
    viewChanged(newView) {
      console.log('View Changed');
      console.log(newView);
    },
    dayClicked(date) {
      console.log('Day clicked');
      console.log(date.getDate());
    },
    timeClicked(dateWithTime) {
      this.preset_data.date = dateWithTime.date;
      this.preset_data.start = dateWithTime.time;
      this.preset_data.end = dateWithTime.time + 1;
      this.openModalAction();
    },
    eventClicked(event) {
      this.eventSelect = event;
      this.openModalUpdateAction();
    },

    selectMember(i) {
      this.selectMember = i;
      this.getTasks(i);
    },
    eventCreated(event) {
      console.log('Event created');
      console.log(event);
    },
    updateSchedulerWeek() {
      console.log('mudanca manual', this.start_week_day);

      if (this.$refs.scheduler) {
        this.schedulerInitialDate = new Date(this.start_week_day);
        this.schedulerInitialDate.setHours(
          this.schedulerInitialDate.getHours() + 3
        );

        this.schedulerInitialDate.setDate(this.schedulerInitialDate.getDate());

        //this.weekChanged(this.start_week_day);
        this.$nextTick(() => {
          if (this.$refs.scheduler) {
            this.$refs.scheduler.$emit(
              'week-changed',
              this.schedulerInitialDate
            );
            // console.log(this.$refs.scheduler.activeDate);
            this.$refs.scheduler.activeDate = moment(this.schedulerInitialDate);
          }
        });
      }
    },
    async getPastYearOfficeCost() {
      this.$store
        .dispatch('getPastYearOfficeCost', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          group: 'offices',
          expense: true,
        })
        .then((resp) => {
          this.costs_variables.average_office_cost = resp.averageValue;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .calendar-wrapper {
    overflow-x: auto;
  }
  .custom-scheduler {
    height: 95vh !important;
    padding-left: 0 !important;
    width: 160vw;
  }
  .v-cal-header {
    padding-bottom: 12px;
  }
  .v-cal-header__actions {
    -webkit-box-pack: justify;
    justify-content: start;
    margin-bottom: 15px;
  }
  .v-cal-content.v-cal-content--month .v-cal-weekdays,
  .v-cal-content.v-cal-content--week .v-cal-weekdays,
  .v-cal-content.v-cal-content--day .v-cal-weekdays {
    font-size: 0.7rem;
  }
  .v-cal-content.v-cal-content--week .v-cal-times,
  .v-cal-content.v-cal-content--day .v-cal-times {
    font-size: 0.65rem;
  }

  .custom-select {
    font-size: 0.7rem;
  }
  .btn-list {
    font-size: 0.7rem;
  }
  .v-cal-header__title-bar .v-cal-header__title {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: start;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .v-cal-event-name {
    font-size: 0.7rem;
  }
  .v-cal-button {
    font-size: 0.7rem;
  }
  .v-cal-hour.all-day {
    display: none !important;
  }
}
.v-cal-weekdays {
  position: sticky;
}

.v-cal-content .v-cal-days {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

.v-cal-content .v-cal-event-item .v-cal-event-time {
  margin: 0;
  display: none;
  overflow-wrap: break-word;
}

.v-cal-content .v-cal-event-item .v-cal-event-name {
  margin-left: 0;
  text-overflow: ellipsis;
}
.v-cal-content .v-cal-event-item {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.custom-scheduler {
  height: 95vh !important;
  padding-left: 0 !important;
}
</style>
