<template>
  <div>
    <b-modal
      v-model="open"
      :title="`Detalhes da tarefa`"
      centered
      hide-footer
      hide-header-close
    >
      <b-card
        :title="`${item.task_title ? item.task_title.task_title : item.title}`"
        :sub-title="`${item.username}`"
        border-variant="warning"
        footer-tag="footer"
      >
      <b-card-text>
          <p>
            Projeto: <b>{{ item.project_code }} - {{ item.project_name }}</b
            ><br />
          </p>
          Execução: <b>{{ item.start }} - {{ item.end }}</b
          ><br />
          Data: <b>{{ formatDate }}</b> <br />
          Prazo para execução:
          <b>{{ this.eventDuration(item.end, item.start) }}</b> <br />
          Prioridade: <b>{{ item.priority ? item.priority : 'Não definida' }}</b
          ><br />
          Status: <b>{{ item.status ? item.status : 'Não definido' }}</b><br />
          Nível da Tarefa: <b>{{ item.level ? item.level == 'tatic' ? 'TÁTICO' : item.level == 'estrategico' ? 'ESTRATÉGICO' : item.level == 'operacional' ? 'OPERACIONAL' : 'Desconhecido' : 'Não definido' }}</b><br />
          Risco: <b>{{ item.risk == 'baixa' ? 'Baixo' : item.risk == 'media' ? 'Médio Risco' : item.risk == 'alta' ? 'Alto Risco' : 'Não definido' }}</b><br />
          Nivel de custo: <b>{{ item.cost == 'baixa' ? 'Baixo' : item.cost == 'media' ? 'Médio Risco' : item.cost == 'alta' ? 'Alto Risco' : 'Não definido' }}</b><br />


          <br /><br />
          Notas: {{ item.notes }}<br />
          despesa fixa da tarefa:
          <b
            >R$
            {{
              item.indirect_cost
                ? numberToMonetary(item.indirect_cost)
                : '(Fechar custos)'
            }}</b
          >
          <br /><br />
          <b>
            ETAPA NO PROJETO:
            {{
              item && item.project_stage && item.project_stage.stage_name
                ? item.project_stage.stage_name
                : item && item.stage && item.stage.stage_name
                ? item.stage.stage_name
                : ''
            }}</b
          >
        </b-card-text>

        <template #footer>
          <b-link href="#" class="card-link" @click="openEditModal"
            >Editar terefa</b-link
          >
          <b-link href="#" class="card-link" @click="closeEditModal"
            >Voltar</b-link
          >
        </template>
      </b-card>
    </b-modal>

    <b-modal
      ref="editModal"
      :title="`Detalhes da tarefa`"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header-close
    >
      <!-- Formulario -->
      <validation-observer ref="ModalProjectCosts">
        <hr class="invoice-spacing mb-3" />
        <b-form>
          <b-row
            class="my-1 m-2 text-left mt-4 d-flex justify-content-center flex-column"
          >
            <!-- PROJETOS -->
            <b-form-group class="mb-2">
              <label for="category" class="font-weight-bold text-primary"
                >Projetos :</label
              >
              <b-form-select id="category" v-model="item.project_id">
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id"
                >
                  {{ project.project_code }} - {{ project.project_name }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="mb-2" v-if="item.project_id">
              <label for="category" class="font-weight-bold text-primary"
                >Etapa do projeto :</label
              ><br />
              <b-form-select
                v-model="item.project_stage_id"
                :options="project_stages"
                value-field="id"
                text-field="stage_name"
                placeholder="Selecione uma etapa"
              ></b-form-select>
            </b-form-group>

            <!-- TITULO -->
            <b-form-group
              class="mb-2"
              v-if="
                currentWorkspace && currentWorkspace.complete_workspace == false
              "
            >
              <label class="font-weight-bold text-primary">Tipo de tarefa:</label>
              <b-form-input v-model="item.title"></b-form-input>
            </b-form-group>

            <div class="w-100 mb-2" v-else v-show="item.project_stage_id">
              <label class="font-weight-bold text-primary">Tipo de tarefa:</label>
              <!-- Garante que o v-select ocupe a largura total -->
              <v-select
                v-model="item.task_title_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Selecione"
                :options="task_title_arr"
                :reduce="(option) => option.id"
                label="name"
              >
                <div slot="no-options">
                  Desculpe, mas o projeto não tem etapa!
                </div>
                <template #list-header>
                  <li
                    class="add-new-header d-flex align-items-center my-50 cursor-pointer"
                    @click="$router.push({ name: 'novo-projeto' })"
                  >
                    <feather-icon icon="PlusIcon" size="16" />
                    <span class="align-middle ml-25">Add Novo Projeto</span>
                  </li>
                </template>
              </v-select>
            </div>

            <!-- DATA DE REFERÊNCIA -->
            <b-form-group class="mb-2" :disabled="item.isRepeatActive">
              <label for="referenceDate" class="font-weight-bold text-primary"
                >Data :</label
              >
              <b-form-input
                id="referenceDate"
                type="date"
                v-model="item.date"
              ></b-form-input>
            </b-form-group>

            <!-- HORA DE INÍCIO -->
            <b-form-group class="mb-2">
              <label for="startTime" class="font-weight-bold text-primary"
                >Hora de Início:</label
              >
              <b-form-input
                maxlength="5"
                id="startTime"
                type="time"
                value="09:20"
                v-model="item.start"
                @input="item.start = formatHour($event)"
              ></b-form-input>
            </b-form-group>

            <!-- HORA DE FIM -->
            <b-form-group class="mb-2">
              <label for="endTime" class="font-weight-bold text-primary"
                >Hora de Fim:</label
              >
              <b-form-input
                maxlength="5"
                id="endTime"
                type="time"
                v-model="item.end"
                @input="item.end = formatHour($event)"
              ></b-form-input>
            </b-form-group>

            <!-- Descricao -->
            <b-form-group class="mb-2">
              <label class="font-weight-bold text-primary">Descriçao:</label>
              <b-form-textarea v-model="item.notes"></b-form-textarea>
            </b-form-group>

            <label for="" class="font-weight-bold text-primary"
              >Prioridade da tarefa:</label
            >
            <b-form-select
              id=""
              v-model="item.priority"
              :options="priorityOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um projeto"
              class="mb-2"
            ></b-form-select>
            <label for="" class="font-weight-bold text-primary"
            >Custo da tarefa:

            <feather-icon
              v-b-tooltip.hover.lefttop.v-primary.html
              :title="`<div style='text-align: left;'><b>NIVEL ESTRATÉGICO:</b> Direção Executiva, Conselho Adm, Diretores, VP. <br><br>
                  <b>NÍVEL TATICO:</b> Gerentes de departamentos, Coordenadores de projeto, Equipe de planejamento.<br><br>
                  <b>NÍVEL OPERACIONAL:</b> Equipes de trabalho, Supervisores, Lideres de equipe, Colaboradores.</div>`"
              icon="InfoIcon"
              size="15"
              style="cursor: pointer"/>
            </label>
          <b-form-select
            id=""
            v-model="item.cost"
            :options="costOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um custo"
            class="mb-2"
          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Risco da tarefa:
            <feather-icon
              v-b-tooltip.hover.lefttop.v-primary.html
              :title="`<div style='text-align: left;'><b>NIVEL ESTRATÉGICO:</b> Direção Executiva, Conselho Adm, Diretores, VP. <br><br>
                  <b>NÍVEL TATICO:</b> Gerentes de departamentos, Coordenadores de projeto, Equipe de planejamento.<br><br>
                  <b>NÍVEL OPERACIONAL:</b> Equipes de trabalho, Supervisores, Lideres de equipe, Colaboradores.</div>`"
              icon="InfoIcon"
              size="15"
              style="cursor: pointer"/>
            </label>
          <b-form-select
            id=""
            v-model="item.risk"
            :options="riskOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um custo"
            class="mb-2"
          ></b-form-select>

            <label for="" class="font-weight-bold text-primary"
              >Estado da tarefa:</label
            >
            <b-form-select
              id=""
              v-model="item.status"
              :options="statusOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um projeto"
              class="mb-2"
            ></b-form-select>

            <label for="" class="font-weight-bold text-primary"
              >Nível de tarefa:
              <feather-icon
                v-b-tooltip.hover.lefttop.v-primary.html
                :title="`<div style='text-align: left;'><b>NIVEL ESTRATÉGICO:</b> Direção Executiva, Conselho Adm, Diretores, VP. <br><br>
                  <b>NÍVEL TATICO:</b> Gerentes de departamentos, Coordenadores de projeto, Equipe de planejamento.<br><br>
                  <b>NÍVEL OPERACIONAL:</b> Equipes de trabalho, Supervisores, Lideres de equipe, Colaboradores.</div>`"
                icon="InfoIcon"
                size="15"
                style="cursor: pointer"
            /></label>
            <b-form-select
              id=""
              v-model="item.level"
              :options="levelOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um nível"
              class="mb-2"
            ></b-form-select>

            <label for="" class="font-weight-bold text-primary"
            >Retrabalho:</label
          >
          <b-form-select
            id=""
            v-model="item.rework"
            :options="reworkOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um nível"
            :disabled="userAccesses && userAccesses.is_common"
            class="mb-2"
          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Trabalho remoto:</label
          >
          <b-form-select
            id=""
            v-model="item.remote"
            :options="remoteOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um nível"
            :disabled="userAccesses && userAccesses.is_common"
            class="mb-2"
          ></b-form-select>
          </b-row>

          <hr class="invoice-spacing mb-3" />
        </b-form>
      </validation-observer>

      <!-- Botoes -->
      <b-row class="justify-content-around d-flex">
        <b-button variant="warning" class="mr-1" @click="closeEditModal"
          >Voltar</b-button
        >
        <b-button variant="danger" @click="confirmDeleteModal" class="mr-1"
          >Deletar</b-button
        >
        <b-button @click="saveTask" class="mr-1">Salvar</b-button>
      </b-row>
      <b-modal ref="modal-confirm" hide-footer centered title="Um momento">
        <p class="my-4">Deseja mesmo excluir a tarefa?</p>
        <hr />
        <b-row class="justify-content-around d-flex">
          <b-button variant="danger" class="mr-2" @click="deleteTask"
            >Sim
          </b-button>
          <b-button variant="warning" class="mr-2" @click="hideDeleteModal"
            >Não
          </b-button>
        </b-row>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {
  BModal,
  BForm,
  BRow,
  BFormGroup,
  BFormSelect,
  BButton,
  BFormInput,
  BFormTextarea,
  BCard,
  BCardText,
  VBTooltip,
  BLink,
} from 'bootstrap-vue';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { differenceInMinutes,format, parseISO } from 'date-fns';
import Ripple from 'vue-ripple-directive';

import moment from 'moment';
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  name: 'new-task',
  props: {
    _item: { type: Object, require: true },
    open: {
      type: Boolean,
      default: false,
    },
    costs_variables: { type: Object, require: false },
  },
  components: {
    BModal,
    BForm,
    BRow,
    BFormGroup,
    BFormSelect,
    BButton,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BCardText,
    BCard,
    BLink,
  },
  data() {
    return {
      workspace_name: '',
      project_stages: [],
      task_title_arr: [],
      priorityOptions: [
        { name: 'Baixa', value: 'Baixa' },
        { name: 'Média', value: 'Media' },
        { name: 'Alta', value: 'Alta' },
      ],
      statusOptions: [
        { name: 'EM EXECUÇÃO', value: 'EM EXECUÇÃO' },
        { name: 'EM PAUSA', value: 'EM PAUSA' },
        { name: 'CONCLUÍDA', value: 'CONCLUÍDA' },
        { name: 'CANCELADA', value: 'CANCELADA' },
      ],
      levelOptions: [
        { name: 'ESTRATÉGICO', value: 'estrategico' },
        { name: 'TÁTICO', value: 'tatic' },
        { name: 'OPERACIONAL', value: 'operacional' },
      ],
      costOptions: [
        { name: 'Baixo Custo', value: 'baixa' },
        { name: 'Medio Custo', value: 'media' },
        { name: 'Alto Custo', value: 'alta' },
      ],
      riskOptions: [
        { name: 'Baixo Risco', value: 'baixa' },
        { name: 'Medio Risco', value: 'media' },
        { name: 'Alto Risco', value: 'alta' },
      ],
      reworkOptions: [
        { name: 'Sim', value: true },
        { name: 'Não', value: false },
      ],
      remoteOptions: [
        { name: 'Sim', value: true },
        { name: 'Não', value: false },
      ],
      hour_per_month: '',
      item: {
        project_id: null,
        title: '',
        date: null,
        start: '',
        end: '',
        notes: '',
        direct_cost: '100',
        real_direct_cost: '100',
        indirect_cost: '100',
        real_indirect_cost: '100',
        priority: '',
        status: '',
        level: '',
        cost: '',
        rework: false,
        remote: false,
        risk: ''
      },

      member_id: '',
      projects: [],
      loading: false,
    };
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    formatDate() {
      return this.open ? format(parseISO(this.item.date), 'dd/MM/yyyy') : '';
    },
  },

  async created() {
    this.loadProjects();

    this.workspace_name = this.$store.getters.currentWorkspace.workspace_title;
  },

  watch: {
    _item: {
      handler(newPreTime) {
        console.log('prop', newPreTime);
        this.item = {
          ...newPreTime,
          start: newPreTime.startTime.slice(0, 5),
          end: newPreTime.endTime.slice(0, 5),
          date: this.dateFormatter(newPreTime.date, 'yyyy-MM-dd'),
        };
        if (this.item.project_id) {
          this.getProjectStages();
          console.log(this.item);
          this.getMemberId();
        }

        if (
          this.currentWorkspace &&
          this.currentWorkspace.complete_workspace == true
        ) {
          this.getAllTaskTitles();
        }
      },
      deep: true,
    },
  },

  methods: {
    async getProjectStages() {
      this.project_stages = [];
      this.$store
        .dispatch('getProjectStages', {
          project_id: this.item.project_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_stages = resp.map((item) => ({
            id: item.id,
            stage_name: item.stage_name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllTaskTitles() {
      this.task_title_arr = [];

      this.$store
        .dispatch('getAllTaskTitles', {
          workspace_id: this.currentWorkspace.id,
          page: 1,
          perPage: 1000,
          inactive: false,
        })
        .then((resp) => {
          resp.data.map((item) => {
            let obj = {
              id: item.id,
              name: item.task_title,
            };
            this.task_title_arr.push(obj);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditModal() {
      this.$nextTick(() => {
        this.$refs.editModal.show();
      });
    },
    closeEditModal() {
      this.$refs.editModal.hide();
      this.$emit('closeModal');
    },
    confirmDeleteModal() {
      this.$refs['modal-confirm'].show();
    },
    hideDeleteModal() {
      this.$refs['modal-confirm'].hide();
    },
    async getMemberId() {
      this.$store
        .dispatch('getUserMember', {
          id:
            this.item &&
            this.item.member &&
            this.item.member.access &&
            this.item.member.access.user_id
              ? this.item.member.access.user_id
              : null,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.hour_per_month =
            resp && resp[0] && resp[0].member && resp[0].member.hour_per_month
              ? resp[0].member.hour_per_month
              : 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveTask() {
      const startTime = this.item.start.split(':');
      const endTime = this.item.end.split(':');

      const startDate = new Date();
      startDate.setHours(startTime[0], startTime[1]);

      const endDate = new Date();
      endDate.setHours(endTime[0], endTime[1]);

      if (endDate <= startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hora de fim deve ser maior que a hora de início',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        });
        return;
      }

      const redate = moment(this.item.date).utcOffset(-6, true);

      this.item.start = this.transformInCompleteData(this.item.start);
      this.item.end = this.transformInCompleteData(this.item.end);
      const duration = (endDate - startDate) / (1000 * 60 * 60);

      this.$store
        .dispatch('updateMyTask', {
          id: this.item.id,
          project_id: this.item.project_id,
          title: this.item.title ? this.item.title : null,
          notes: this.item.notes,
          date: redate,
          start: this.item.start,
          end: this.item.end,
          updated_at: new Date(),
          priority: this.item.priority,
          status: this.item.status,
          workspace_id: this.$store.getters.currentWorkspace.id,
          indirect_cost: Math.round(
            (this.costs_variables.average_office_cost / this.hour_per_month) *
              duration
          ),
          task_title_id: this.item.task_title_id
            ? this.item.task_title_id
            : null,
          project_stage_id: this.item.project_stage_id
            ? this.item.project_stage_id
            : null,
          level: this.item.level,
          risk: this.item.risk,
          rework: this.item.rework,
          remote: this.item.remote,
          cost: this.item.cost,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa atualizada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.closeEditModal();
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'ErrorIcon',
              variant: 'danger',
              text: err.response?.data?.message,
            },
          });
          console.log(err);
        });
    },
    async deleteTask() {
      this.$store
        .dispatch('deleteTask', {
          id: this.item.id,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa excluida com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.closeEditModal();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async loadProjects() {
      try {
        await this.$store
          .dispatch('_getProjects', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            this.projects = resp.data;
          });
      } catch (error) {
        console.error('Erro ao carregar projetos', error);
      }
    },

    transformNumberInHour(numero) {
      if (numero >= 0 && numero <= 23) {
        const horasFormatadas = numero < 10 ? `0${numero}` : `${numero}`;
        return `${horasFormatadas}:00`;
      } else {
        return 'Número inválido. Por favor, insira um número de 0 a 23.';
      }
    },

    transformInCompleteData(hour) {
      const dataAtual = new Date(this.item.date);
      dataAtual.setDate(dataAtual.getDate() + 1);
      dataAtual.setHours(hour.split(':')[0]);
      dataAtual.setMinutes(hour.split(':')[1]);

      return this.dateFormatter(dataAtual, 'yyyy-MM-dd HH:mm:ss');
    },

    formatHour(hour) {
      const [hours, minutes] = hour.split(':');
      if (parseInt(minutes) > 59) {
        return `${hours}:59`;
      }
      return hour;
    },
    onlyHours(dataString) {
      const data = new Date(dataString);
      data.setHours(data.getHours());

      const horas = data.getHours();
      const minutos = data.getMinutes();

      const horasFormatadas = horas < 10 ? '0' + horas : horas;
      const minutosFormatados = minutos < 10 ? '0' + minutos : minutos;

      return `${horasFormatadas}:${minutosFormatados}`;
    },

    eventDuration(endTime, startTime) {
      const end = new Date(`01 january 2024 ${endTime} GMT-3`);
      const start = new Date(`01 january 2024 ${startTime} GMT-3`);

      const totalMinutes = differenceInMinutes(end, start);

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    },
  },
};
</script>

<style></style>
