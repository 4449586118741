<template>
  <b-card title="">
    <b-table
      responsive="sm"
      small
      id="my-table"
      hover
      striped
      :fields="fields"
      :items="items"
      :per-page="10"
      :current-page="0"
      :busy="isBusy"
      class="text-left"
      show-empty
    >
      <template #empty>
        <div class="p-2">
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              Não existe <strong>Evento</strong> para a semana escolhida, tente mudar a data buscar novos resultados!
            </p>
            <hr />
          </b-alert>
        </div>
      </template>
      <template v-slot:cell(title)="{ item }">
        <strong>{{ item.title ? item.title : item.task_title.task_title }}</strong
        ><br />
        <small> {{ item.notes ? 'Obs.: ' + item.notes : 'Sem descrição' }}</small>
      </template>
      <template v-slot:cell(project)="{ item }">
        <strong>#{{ item.project.project_code }}</strong>
        <p>{{ item.project.project_name }}</p>
      </template>
      <template v-slot:cell(actions)="props">
        <div @click="handleUpdateModal(props.item)">
          <feather-icon v-b-tooltip.hover.lefttop.v-primary title="Editar fornecedor" icon="EditIcon" size="15" />Editar
        </div>
      </template>
      <template v-slot:cell(username)="{ item }">
        <div>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center'
            }"
          >
            <span
              :style="{
                borderRadius: '50%',
                //   display: 'block',
                background: item.member.color,
                width: '10px',
                height: '10px'
              }"
            >
            </span>
            <p
              :style="{
                height: 'auto',
                paddingLeft: '5px',
                paddingTop: '10px'
              }"
            >
              {{ item.member.access.user.username }}
            </p>
          </div>
          <small>{{ item.member.access.user.email }}</small>
        </div>
      </template>

      <template v-slot:cell(date)="{ item }">
        {{ dateFormatter(item.date, 'dd/MM/yyyy') }}
      </template>
      <template v-slot:cell(start)="{ item }">
        {{ item.start.slice(0, 5) }}
      </template>
      <template v-slot:cell(end)="{ item }">
        {{ item.end.slice(0, 5) }}
      </template>
      <template v-slot:cell(comments)="{ item }">
        {{ item.notes }}
      </template>
    </b-table>

    <b-pagination
      class="justify-content-center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="getPage"
      aria-controls="my-table"
    ></b-pagination>
    <div class="mr-2 d-flex justify-content-end">
      <strong>Total de Tarefas: {{ totalRows }} </strong>
    </div>
  </b-card>
</template>

<script>
import { BCard, BTable, BPagination, VBTooltip, BAlert } from 'bootstrap-vue';

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BTable,
    BPagination,
    BAlert
  },
  props: {
    items: { type: Array, require: true },
    currentPage: {type: Number},
    totalRows: {type: String}
  },
  data: () => ({
    modalNewSupplier: false,
    modalDeleteSupplier: false,
    isBusy: false,
    loading: true,
    perPage: 10,
    rows: 0,
    fields: [
      {
        key: 'title',
        label: 'Tarefa',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        key: 'project',
        label: 'Projeto',
        sortable: true,
        thStyle: { width: '20%' }
      },
      {
        key: 'actions',
        label: 'Ações',
        thStyle: { width: '20%' }
      },
      {
        key: 'username',
        label: 'Usuário',
        sortable: true
      },
      {
        key: 'date',
        label: 'Data',
        sortable: true
      },
      {
        key: 'start',
        label: 'Início',
        sortable: true
      },
      {
        key: 'end',
        label: 'Fim',
        sortable: true
      }
    ],
    taxRecordsList: false
  }),
  created() {
    this.rows = this.items.length;
  },
  watch: {
    // items: {
    //   handler(newItems) {
    //     this.totalRows = newItems.length;
    //   },
    //   deep: true
    // }
  },

  methods: {
    getPage(val) {
      this.$emit('getTasks', val);
    },
    closeModal() {
      this.modalNewSupplier = false;
      this.modalDeleteSupplier = false;
    },

    cleanFilter() {
      this.modelTaxRecords.search = '';
    },

    openDeleteModal(item) {
      this.modalDeleteSupplier = true;
      this.$store.modalSupplier = item;
    },

    handleUpdateModal(item) {
      const formattedData = {
        ...item,
        username: item.member.access.user.username,
        project_code: item.project.project_code,
        project_name: item.project.project_name,
        date: item.date,
        startTime: item.start.slice(0, 5),
        endTime: item.end.slice(0, 5),
        name: item.title,
        comments: item.notes
      };
      this.$emit('openUpdateModal', formattedData);
    },
    handleUpdateList() {
      this.getAllSuppliers();
    }
  }
};
</script>
