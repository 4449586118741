<template>
  <b-modal
    :title="`Adicionar tarefa a ${workspace_name}`"
    v-model="openModal"
    centered
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <!-- Formulario -->
    <validation-observer ref="ModalProjectCosts">
      <hr class="invoice-spacing mb-3" />
      <b-form>
        <b-row
          class="my-1 m-2 text-left mt-4 d-flex justify-content-center flex-column"
        >
          <!-- PROJETOS -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Projetos :</label
            ><br />
            <label>
              <div
                @click="handleShowArquived()"
                :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }"
              >
                {{
                  showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados'
                }}
              </div></label
            >
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="item.project_id"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
              @input="getProjectStages"
            ></b-form-select>
          </b-form-group>

          <b-form-group class="mb-2" v-if="item.project_id">
            <label for="category" class="font-weight-bold text-primary"
              >Etapa do projeto :</label
            ><br />
            <b-form-select
              v-model="item.project_stage_id"
              :options="project_stages"
              value-field="id"
              text-field="stage_name"
              placeholder="Selecione uma etapa"
            ></b-form-select>
          </b-form-group>

          <!-- USUARIOS -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Usuários :</label
            >
            <b-form-select id="category" v-model="item.users"  @change="getMemberId()">
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.username }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- TITULO -->
          <b-form-group
            class="mb-2"
            v-if="
              currentWorkspace && currentWorkspace.complete_workspace == false
            "
          >
            <label class="font-weight-bold text-primary">Tipo de tarefa:</label>
            <b-form-input v-model="item.title"></b-form-input>
          </b-form-group>

          <div class="w-100 mb-2" v-else v-show="item.project_stage_id">
            <label class="font-weight-bold text-primary">Tipo de tarefa:</label>
            <!-- Garante que o v-select ocupe a largura total -->
            <v-select
              v-model="item.selected_task_title"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Selecione"
              :options="task_title_arr"
              :reduce="(option) => option.id"
              label="name"
            >
              <div slot="no-options">
                Desculpe, mas o projeto não tem etapa!
              </div>
              <template #list-header>
                <li
                  class="add-new-header d-flex align-items-center my-50 cursor-pointer"
                  @click="$router.push({ name: 'novo-projeto' })"
                >
                  <feather-icon icon="PlusIcon" size="16" />
                  <span class="align-middle ml-25">Add Novo Projeto</span>
                </li>
              </template>
            </v-select>
          </div>

          <!-- DATA DE REFERÊNCIA -->
          <b-form-group class="mb-2" :disabled="item.isRepeatActive">
            <label for="referenceDate" class="font-weight-bold text-primary"
              >Data :</label
            >
            <b-form-input
              id="referenceDate"
              type="date"
              v-model="item.date"
            ></b-form-input>
          </b-form-group>

          <!-- HORA DE INÍCIO -->
          <b-form-group class="mb-2">
            <label for="startTime" class="font-weight-bold text-primary"
              >Hora de Início:</label
            >
            <b-form-input
              maxlength="5"
              id="startTime"
              type="time"
              value="09:20"
              v-model="item.start"
              @input="item.start = formatHour($event)"
            ></b-form-input>
          </b-form-group>

          <!-- HORA DE FIM -->
          <b-form-group class="mb-2">
            <label for="endTime" class="font-weight-bold text-primary"
              >Hora de Fim:</label
            >
            <b-form-input
              maxlength="5"
              id="endTime"
              type="time"
              v-model="item.end"
              @input="item.end = formatHour($event)"
            ></b-form-input>
          </b-form-group>

          <!-- Descricao -->
          <b-form-group class="mb-2">
            <label class="font-weight-bold text-primary">Descriçao:</label>
            <b-form-textarea v-model="item.notes"></b-form-textarea>
          </b-form-group>

          <label for="" class="font-weight-bold text-primary"
            >Prioridade da tarefa:</label
          >
          <b-form-select
            id=""
            v-model="item.priority"
            :options="priorityOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um projeto"
            class="mb-2"
          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Custo da tarefa:

            <feather-icon
              v-b-tooltip.hover.lefttop.v-primary.html
              :title="`<div style='text-align: left;'><b>NIVEL ESTRATÉGICO:</b> Direção Executiva, Conselho Adm, Diretores, VP. <br><br>
                  <b>NÍVEL TATICO:</b> Gerentes de departamentos, Coordenadores de projeto, Equipe de planejamento.<br><br>
                  <b>NÍVEL OPERACIONAL:</b> Equipes de trabalho, Supervisores, Lideres de equipe, Colaboradores.</div>`"
              icon="InfoIcon"
              size="15"
              style="cursor: pointer"/>
            </label>
          <b-form-select
            id=""
            v-model="item.cost"
            :options="costOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um custo"
            class="mb-2"
          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Risco da tarefa:
            <feather-icon
              v-b-tooltip.hover.lefttop.v-primary.html
              :title="`<div style='text-align: left;'><b>NIVEL ESTRATÉGICO:</b> Direção Executiva, Conselho Adm, Diretores, VP. <br><br>
                  <b>NÍVEL TATICO:</b> Gerentes de departamentos, Coordenadores de projeto, Equipe de planejamento.<br><br>
                  <b>NÍVEL OPERACIONAL:</b> Equipes de trabalho, Supervisores, Lideres de equipe, Colaboradores.</div>`"
              icon="InfoIcon"
              size="15"
              style="cursor: pointer"/>
            </label>
          <b-form-select
            id=""
            v-model="item.risk"
            :options="riskOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um custo"
            class="mb-2"
          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Estado da tarefa:</label
          >
          <b-form-select
            id=""
            v-model="item.status"
            :options="statusOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um projeto"
            class="mb-2"

          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Nível de tarefa:
            <feather-icon
              v-b-tooltip.hover.lefttop.v-primary.html
              :title="`<div style='text-align: left;'><b>NIVEL ESTRATÉGICO:</b> Direção Executiva, Conselho Adm, Diretores, VP. <br><br>
                  <b>NÍVEL TATICO:</b> Gerentes de departamentos, Coordenadores de projeto, Equipe de planejamento.<br><br>
                  <b>NÍVEL OPERACIONAL:</b> Equipes de trabalho, Supervisores, Lideres de equipe, Colaboradores.</div>`"
              icon="InfoIcon"
              size="15"
              style="cursor: pointer"
           
          /></label>
          <b-form-select
            id=""
            v-model="item.level"
            :options="levelOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um nível"
               :disabled="userAccesses && userAccesses.is_common"
               class="mb-2"
          ></b-form-select>
          <label for="" class="font-weight-bold text-primary"
            >Retrabalho:</label
          >
          <b-form-select
            id=""
            v-model="item.rework"
            :options="reworkOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um nível"
            :disabled="userAccesses && userAccesses.is_common"
            class="mb-2"
          ></b-form-select>

          <label for="" class="font-weight-bold text-primary"
            >Trabalho remoto:</label
          >
          <b-form-select
            id=""
            v-model="item.remote"
            :options="remoteOptions"
            value-field="value"
            text-field="name"
            placeholder="Selecione um nível"
            :disabled="userAccesses && userAccesses.is_common"
            class="mb-2"
          ></b-form-select>
        </b-row>

        <hr class="invoice-spacing mb-3" />
      </b-form>
    </validation-observer>

    <!-- Botoes -->
    <b-row class="justify-content-around d-flex">
      <!-- back -->
      <b-button variant="warning" class="mr-2" @click="$emit('closeModal')"
        >Voltar
      </b-button>

      <!-- back -->
      <b-button @click="saveTask" class="mr-2">Salvar </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {
  BModal,
  BForm,
  BRow,
  BFormGroup,
  BFormSelect,
  BButton,
  BFormInput,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  name: 'new-task',
  props: {
    pre_time: { type: Object, require: false },
    open: {
      type: Boolean,
      default: false,
    },
    costs_variables: { type: Object, require: false },
  },
  components: {
    BModal,
    BForm,
    BRow,
    BFormGroup,
    BFormSelect,
    BButton,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
  },
  data() {
    return {
      openModal: this.open,
      task_title_arr: [],
      showArquivded: false,
      workspace_name: '',
      project_stages: [],
      priorityOptions: [
        { name: 'Baixa', value: 'Baixa' },
        { name: 'Média', value: 'Media' },
        { name: 'Alta', value: 'Alta' },
      ],
      statusOptions: [
        { name: 'EM EXECUÇÃO', value: 'EM EXECUÇÃO' },
        { name: 'EM PAUSA', value: 'EM PAUSA' },
        { name: 'CONCLUÍDA', value: 'CONCLUÍDA' },
        { name: 'CANCELADA', value: 'CANCELADA' },
      ],
      reworkOptions: [
        { name: 'Sim', value: true },
        { name: 'Não', value: false },
      ],
      remoteOptions: [
        { name: 'Sim', value: true },
        { name: 'Não', value: false },
      ],
      levelOptions: [
        { name: 'ESTRATÉGICO', value: 'estrategico' },
        { name: 'TÁTICO', value: 'tatic' },
        { name: 'OPERACIONAL', value: 'operacional' },
      ],
      costOptions: [
        { name: 'Baixo Custo', value: 'baixa' },
        { name: 'Medio Custo', value: 'media' },
        { name: 'Alto Custo', value: 'alta' },
      ],
      riskOptions: [
        { name: 'Baixo Risco', value: 'baixa' },
        { name: 'Medio Risco', value: 'media' },
        { name: 'Alto Risco', value: 'alta' },
      ],
      item: {
        project_id: null,
        title: '',
        date: null,
        start: '',
        end: '',
        notes: '',
        direct_cost: '0',
        real_direct_cost: '0',
        indirect_cost: '0',
        real_indirect_cost: '0',
        selected_task_title: '',
        priority: 'Baixa',
        risk: 'baixa',
        cost: 'baixa',
        rework: false,
        remote: false,
        status: 'EM EXECUÇÃO',
        level: 'operacional',

      },
      member_id: '',
      users: [],
      projects: [],
      loading: false,
      hour_per_month: '',
    };
  },
  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    currentUser() {
      return this.$store.getters.user.accesses.find(
        (a) => a.workspace_id == this.$store.getters.currentWorkspace.id
      );
    },
    userAccesses() {
      return this.$store.getters.userAccesses;
    },
  },

  async created() {
    this.getAllUsers();
    this.getProjects();
    if (
      this.currentWorkspace &&
      this.currentWorkspace.complete_workspace == true
    ) {
      this.getAllTaskTitles();
    }

    this.workspace_name = this.$store.getters.currentWorkspace.workspace_title;
  },

  watch: {
    open(newVal) {
      this.openModal = newVal;
    },
    openModal(newVal) {
      if (!newVal) {
        this.$emit('update:open', false);
      }
    },
    pre_time: {
      handler(newPreTime) {
        this.item.users = newPreTime.users;
        this.item.start = this.transformNumberInHour(newPreTime.start);
        this.item.date = this.dateFormatter(newPreTime.date, 'yyyy-MM-dd');
        this.item.end = this.transformNumberInHour(newPreTime.end);
      },
      deep: true,
    },
  },

  methods: {
    async getAllTaskTitles() {
      this.task_title_arr = [];

      this.$store
        .dispatch('getAllTaskTitles', {
          workspace_id: this.currentWorkspace.id,
          page: 1,
          perPage: 1000,
          inactive: false,
        })
        .then((resp) => {
          resp.data.map((item) => {
            let obj = {
              id: item.id,
              name: item.task_title,
            };
            this.task_title_arr.push(obj);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProjectStages() {
      this.project_stages = [];
      this.$store
        .dispatch('getProjectStages', {
          project_id: this.item.project_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_stages = resp.map((item) => ({
            id: item.id,
            stage_name: item.stage_name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllUsers() {
      if (!this.userList) {
        this.$store
          .dispatch('getAllUsersWorkspaces', {
            id: this.currentWorkspace.id,
            inactive: false,
            page: 1,
            perPage: 1000,
          })
          .then((resp) => {
            if (resp) {
              this.users = resp.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
    async getMemberId() {
      await this.$store
      .dispatch('getUserMember', {
        id: this.item.users,
        workspace_id: this.$store.getters.currentWorkspace.id,
      })
      .then((resp) => {
        console.log('membro ', resp)
        this.member_id = resp[0].member_id;
        this.hour_per_month = resp && resp[0] && resp[0].member &&  resp[0].member.hour_per_month ? resp[0].member.hour_per_month : 0
      })
      .catch((err) => {
        console.log(err);
      });
      console.log('this.hour_per_month',this.hour_per_month)
    },
    async saveTask() {

      const startTime = this.item.start.split(':');
      const endTime = this.item.end.split(':');

      const startDate = new Date();
      startDate.setHours(startTime[0], startTime[1]);

      const endDate = new Date();
      endDate.setHours(endTime[0], endTime[1]);

      if (endDate <= startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hora de fim deve ser maior que a hora de início',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        });
        return;
      }
      const redate = moment(this.item.date).utcOffset(-6, true);

      this.item.start = this.transformInCompleteData(this.item.start);
      this.item.end = this.transformInCompleteData(this.item.end);

      const duration = (endDate - startDate) / (1000 * 60 * 60);

      await this.$store
        .dispatch('saveMyTask', {
          ...this.item,
          date: redate,
          member_id: this.member_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
          indirect_cost: parseInt(
            (this.costs_variables.average_office_cost /
              this.hour_per_month) *
              duration
          ),
          created_by: this.currentUser.id
        })
        .then((resp) => {
          if (resp) {
            this.item = {
              project_id: null,
              title: '',
              date: null,
              start: '',
              end: '',
              notes: '',
              direct_cost: '0',
              real_direct_cost: '0',
              indirect_cost: '0',
              real_indirect_cost: '0',
            };
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa cadastrada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$emit('closeModal');
          }
        })
        .catch((err) => {
          const errorMessage =
            err.response?.data?.message ||
            err.message ||
            'An unexpected error occurred';
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMessage,
              icon: 'ErrorIcon',
              variant: 'danger',
            },
          });
        });
    },

    async loadProjects() {
      try {
        await this.$store
          .dispatch('_getProjects', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            this.projects = resp;
          });
      } catch (error) {
        console.error('Erro ao carregar projetos', error);
      }
    },
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          resp.data.map((e) => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${
                  e.is_archived ? '(Arquivado)' : ''
                }`,
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${
                    e.is_archived ? '(Arquivado)' : ''
                  }`,
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    transformNumberInHour(numero) {
      if (numero >= 0 && numero <= 23) {
        const horasFormatadas = numero < 10 ? `0${numero}` : `${numero}`;
        return `${horasFormatadas}:00`;
      } else {
        return 'Número inválido. Por favor, insira um número de 0 a 23.';
      }
    },

    transformInCompleteData(hour) {
      const dataAtual = new Date(this.item.date);
      dataAtual.setDate(dataAtual.getDate() + 1);
      dataAtual.setHours(hour.split(':')[0]);
      dataAtual.setMinutes(hour.split(':')[1]);

      return this.dateFormatter(dataAtual, 'yyyy-MM-dd HH:mm:ss');
    },

    formatHour(hour) {
      console.log(hour);
      const [hours, minutes] = hour.split(':');
      if (parseInt(minutes) > 59) {
        return `${hours}:59`;
      }
      return hour;
    },
    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getProjects();
    },
  },
};
</script>

<style></style>
